import CustomMadeSoftwareAdvantagesandDisadvantages from "/static/blog/Blog Cover Optimized/Custom Made Software Advantages and Disadvantages.jpg"
import UltimateGuide from "/static/blog/Blog Cover Optimized/Custom Made Software Development The Ultimate Guide for 2023.jpg"
import CuttingEdge from "/static/blog/Blog Cover Optimized/Cutting Edge Tech for Custom Software Development.jpg"
import Expect from "/static/blog/Blog Cover Optimized/What can I expect from a Custom Software Developer.jpg"
import Agile from "/static/blog/Blog Cover Optimized/What is Agile Project Management in Software Development.jpg"
import Prefer from "/static/blog/Blog Cover Optimized/Which kind of industries prefer Custom Made Software.jpg"
import Need from "/static/blog/Blog Cover Optimized/Why do you need a Custom Software Solution for your Business.jpg"
import Important from "/static/blog/Blog Cover Optimized/What is Custom Software Development and why is it important.jpg"
import Best from "/static/blog/Blog Cover Optimized/How to choose the Best Custom Software Development Company.jpg"
import Average from "/static/blog/Blog Cover Optimized/What is the average cost of Custom Software Development.jpg"
import Straightforward from "/static/blog/Blog Cover Optimized/The straightforward guide to Product Strategy.jpg"
import Sprints from "/static/blog/Blog Cover Optimized/How Design Sprints and Product Discovery Play Together.jpg"
import Phases from "/static/blog/Blog Cover Optimized/The 7 Strategic Phases of the Product Planning Process.jpg"
import Discovery from "/static/blog/Blog Cover Optimized/Product Discvoery Workshop Step-By-Step guide for Software Projects.jpg"
import Strategy from "/static/blog/Blog Cover Optimized/What is a Product Strategy, and how do you develop one.jpg"
import Create from "/static/blog/Blog Cover Optimized/How important is it to create a Product Strategy.jpg"
import Framework from "/static/blog/Blog Cover Optimized/What is Product Discovery Framework & Examples.jpg"
import Essentials from "/static/blog/Blog Cover Optimized/What are the essentials of Strategic Planning for a Product.jpg"
import GreatProduct from "/static/blog/Blog Cover Optimized/How a Product Design Sprint will help you build a great product.jpg"
import GreatStrategy from "/static/blog/Blog Cover Optimized/What is a Great Product Development Strategy.jpg"

export default [
  {
    id: 1,
    img: CustomMadeSoftwareAdvantagesandDisadvantages,
    date: "02 July, 2023",
    header: "Custom Made Software Advantages and Disadvantages",
    text: "There are basically two alternatives available when it comes to using specialized software: bespoke software and off-the-shelf software. Which option is preferable is unclear...",
  },
  {
    id: 2,
    img: UltimateGuide,
    date: "05 Aug, 2023",
    header: "Custom-Made Software Development: The Ultimate Guide for 2023",
    text: "Today, many software programmers surround us on all sides in our daily lives. Whether you're a frequent user or a company owner, you need to keep up with technological advancements. Custom software might prove to be the best option in that situation.",
    // This link will work for diffrent header then slug create from header
    updatedLink: "custom-software-development-ultimate-guide-2023/",
  },
  {
    id: 3,
    img: CuttingEdge,
    date: "26 Aug, 2023",
    header: "Cutting Edge Technologies for Custom Software Development",
    text: "Although the world is changing quickly, the high-tech industry changes twice as quickly. Custom software development firms make every effort to create high-quality solutions that meet their clients' objectives while also leveraging cutting-edge technology to go above and beyond their",
  },
  {
    id: 4,
    img: Expect,
    date: "17 Sept, 2023",
    header: "What can I expect from a Custom Software Developer?",
    text: "The practice of creating software applications specifically for a person or business is known as custom software development. Contrary to commercial off-the-shelf (COTS) software, bespoke alternatives typically focus on a particular issue.",
  },
  {
    id: 5,
    img: Agile,
    date: "20 Oct, 2023",
    header: "What is Agile Project Management in Software Development?",
    text: "One of the cutting-edge approaches to project management presented recently is agile project management. It is widely used in the management of software development projects. To understand agile project management, it is helpful to compare it to the software development process.",
  },
  {
    id: 6,
    img: Prefer,
    date: "24 Nov, 2023",
    header: "Which Kind of Industries Prefer Custom Made Software?",
    text: "Without software, running a firm is currently impossible. Every business uses it, whether for straightforward tasks like email management or easy research, or for more complicated ones like employee time tracking, bookkeeping, or project management.",
  },
  {
    id: 7,
    img: Need,
    date: "12 Dec, 2023",
    header: "Why do you Need a Custom Software Solution for your Business?",
    text: "Businesses are looking for novel methods to stay on top of trends as a result of advancing technology and rising client expectations. In this context, a specific kind of software has become more well-known as maybe the finest strategy for staying ahead of the curve.",
  },
  {
    id: 8,
    img: Important,
    date: "02 Jan, 2024",
    header: "What is Custom Software Development and why is it Important?",
    text: "Whether you're a group of software engineers, an entrepreneur, or the owner of a company, you recognize the value of efficient software administration and development.",
  },
  {
    id: 9,
    img: Best,
    date: "14 Feb, 2024",
    header: "How to Choose the Best Custom Software Development Company",
    text: "When it comes to custom software development, choosing the right company to work with is crucial. A good company will help you turn your ideas into a reality..",
  },
  {
    id: 10,
    img: Average,
    date: "27 Feb, 2024",
    header: "What is the Average cost of Custom Software Development?",
    text: "Custom software development can be a significant investment for a business, but it can also bring a lot of value by improving efficiency, streamlining processes, and increasing revenue.",
  },
  {
    id: 11,
    img: Straightforward,
    date: "13 Mar, 2024",
    header: "The Straightforward Guide to Product Strategy",
    text: "A product strategy is a broad plan that outlines the objectives that your business wants to accomplish with a new product and the specific processes needed to get there..",
  },
  {
    id: 12,
    img: Sprints,
    date: "21 Mar, 2024",
    header: "How Design Sprints and Product Discovery Play Together",
    text: "Both design sprints and product discovery are techniques used to assist teams in making choices more quickly and effectively. In only five days, teams may test new ideas and solve..",
  },
  {
    id: 13,
    img: Discovery,
    date: "17 Apr, 2024",
    header:
      "Product Discovery Workshop – Step-By-Step Guide for Software Projects",
    text: "A product discovery workshop is a collaborative event where stakeholders, including product managers, designers, developers, and business leaders, gather to discuss and identify the..",
    updatedLink: "product-discovery-workshop-guide/",
  },
  {
    id: 14,
    img: Strategy,
    date: "26 Apr, 2024",
    header: "What is a product strategy, and how do you develop one?",
    text: "A product strategy is a blueprint or method for creating, promoting, and commercializing a product or series of products. It describes the target market, a value proposition, and aims..",
    updatedLink: "product-strategy-development-guide",
  },
  {
    id: 15,
    img: Phases,
    date: "12 May, 2024",
    header: "The 7 Strategic Phases of the Product Planning Process",
    text: "Product planning is the process of coming up with a new product concept and determining all of its specifications, including its features, pricing, advertising, distribution, and more..",
  },
  {
    id: 16,
    img: Create,
    date: "02 June, 2024",
    header: "How Important is it to Create a Product Strategy",
    text: "A sensible product strategy is frequently the first step in the design and marketing of a new product? You can make available a thorough strategy that outlines the primary target..",
  },
  {
    id: 17,
    img: Framework,
    date: "28 June, 2024",
    header: "What Is Product Discovery? Framework & Examples",
    text: "Choosing what to create is the process of product discovery. It outlines a number of things you must complete before beginning the product distribution procedure..",
    updatedLink: "product-discovery-framework-examples/",
  },
  {
    id: 18,
    img: Essentials,
    date: "13 July, 2024",
    header: "What are the essentials of strategic planning for a product?",
    text: `The creation of a product strategy doesn't have to be difficult to be successful. And yet, throughout two decades the product manager has repeatedly run into companies that over..`,
  },
  {
    id: 19,
    img: GreatProduct,
    date: "23 July, 2024",
    header: "How a Product Design Sprint will help you build a great product?",
    text: "A designer and project manager will work with you over the course of four days to bring your vision to life. They will coordinate your team to assist in problem-solving and lead your team by..",
  },
  {
    id: 20,
    img: GreatStrategy,
    date: "07 Aug, 2024",
    header: "What is a Great Product Development Strategy?",
    text: "A plan of action for realizing a vision is a product development strategy. There are several stages you must take to get at a final product; by outlining these processes up front, you may make the..",
  },
]
