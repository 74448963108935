import React from "react"
import Grid from "@mui/material/Grid"
import "./Card.scss"
import { substring } from "../../utils/helpers"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
function Card({ cardData, col, gap, skipFirst = false }) {
  function formatString(str) {
    str = str.toLowerCase()
    str = str.replace(/\?/g, "")
    str = str.replace(/\s+/g, "-")
    if (str.endsWith("?")) {
      return str.slice(0, -1)
    } else {
      return str
    }
  }
  return (
    <div className="blog-card">
      <Grid container spacing={gap} justifyContent="flex-start">
        {cardData
          .slice(0)
          .reverse()
          .map((card, i) => {
            const link = card.updatedLink
              ? `/blogs/${card.updatedLink}`
              : `/blogs/${formatString(card.header)}`
            // Ignore first latest blog
            if (i === 0 && skipFirst) return null
            return (
              <Grid item md={col} key={i}>
                <Link to={link} style={{ textDecoration: "none" }}>
                  <div className="blog-single-card">
                    <div className="img-wrapper text-center">
                      <img
                        src={card.img}
                        alt="img"
                        className="img-wrapper-class"
                        loading="lazy"
                      />
                    </div>
                    <p className="date-card">{card.date}</p>
                    <p className="header-card">{card.header}</p>
                    <p className="text-card">{substring(card.text, 170)}</p>
                  </div>
                </Link>
              </Grid>
            )
          })}
      </Grid>
    </div>
  )
}

export default Card
