import React from "react"
import Button from "../../shared/Button"
import "./blogSection.scss"
import Card from "../../shared/BlogCard"
import blogs from "../../shared/Data/blogs"

function BlogSection() {
  const topBlogs = blogs
    .slice(0)
    .reverse()
    .filter((blog, index) => index < 3)
  return (
    <div className="container">
      <div className="blog-section-wrapper">
        <Card cardData={topBlogs.slice(0).reverse()} col={4} gap={4} />
        <div className="btn-wrapper">
          <Button text="View All Blogs" variant="fill" href="/blogs" />
        </div>
      </div>
    </div>
  )
}

export default BlogSection
