import React from "react"

import Heading from "../Heading"
import ContactForm from "./ContactForm"
import "./Footer.scss"
import Scroll from "../../utils/Scroll"
export default function ContactUs() {
  return (
    <div className="contact-us">
      <div className="container position-relative">
        <a href="#top" className="scroll-top-global d-flex">
          <div className="icon">
            <Scroll direction="up" />
          </div>
          <div className="text">SKIP TO TOP</div>
        </a>

        <div className="row">
          <div
            className="col-12"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="1400"
            data-aos-delay="200"
          >
            <Heading variant="40" className="pb-2 text-capitalize">
              Ready to get started?
            </Heading>
            <div className="text">
              Get in touch and our team will be right with you!
            </div>
          </div>
          <div
            className="col-12"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="1400"
            data-aos-delay="200"
          >
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  )
}
