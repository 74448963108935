import React, { useState } from "react"
import Button from "../../shared/Button"
import { styled } from "@mui/material/styles"
import { TextField, Grid, Alert } from "@mui/material"
import "./Footer.scss"
import Select from "./SelectHook"
import { navigate } from "gatsby"
import Loader from "../../utils/Loader"
import { fileToAttachmentFormat, sendMail } from "../../utils/helpers"
// import useFetch from "../../utils/hubspotRequest"
const CssTextField = styled(TextField)({
  "& .MuiFormLabel-root": {
    color: "#2F2F2F",
    fontFamily: "Poppins",
    fontWeight: 500,
    fontSize: "1rem",
    marginBottom: "6px",
    lineHeight: "1.1rem",
  },
  "& .MuiFormLabel-asterisk.MuiInputLabel-asterisk": {
    color: "red",
  },
  "& label.Mui:after": {
    color: "#B5B5B5",
  },
  "& label.Mui-focused": {
    color: "#B5B5B5",
  },

  "& .MuiInput-underline:after": {
    borderBottom: "2px solid #183266",
  },
  "& .MuiInput-root:before": {
    borderBottom: 0,
    borderBottom: "2px solid #E6E6E6",
  },
  "& .MuiInput-input": {
    cursor: "auto",
  },
  "& .MuiInput-input": {
    cursor: "auto",
  },
})

const helpOptions = [
  {
    id: 1,
    name: "Product Strategy",
    value: "Product Strategy",
    budget: false,
  },
  {
    id: 2,
    name: "Product Design",
    value: "Product Design",
    budget: false,
  },
  {
    id: 3,
    name: "Custom Software Development",
    value: "Custom Software Development",
    budget: false,
  },
  {
    id: 4,
    name: "Data Engineering",
    value: "Data Engineering",
    budget: false,
  },
  {
    id: 5,
    name: "Team Augmentation",
    value: "Team Augmentation",
    budget: false,
  },
  {
    id: 6,
    name: "Partnership",
    value: "Partnership",
    budget: true,
  },
  {
    id: 7,
    name: "Other",
    value: "Other",
    budget: true,
  },
]
const budgetOptions = [
  {
    id: 1,
    name: "$5000-$10000",
    value: "5000-10000",
  },
  {
    id: 2,
    name: "$10000-$50000",
    value: "10000-50000",
  },
  {
    id: 3,
    name: "$50000+",
    value: "50000",
  },
]

export default function ContactForm() {
  const [open, setOpen] = React.useState(false)
  const [isFailed, setIsFailed] = React.useState(false)
  const [clearSelectFields, setClearSelectFields] = React.useState(false) //1

  const [state, setState] = useState({
    name: "",
    email: "",
    company: "",
    help: "",
    budget: "",
    message: "",
  })

  const [budgetDisable, setbudgetDisable] = useState(true)

  const onChangeHandler = e => {
    const value = e.target.value
    if (e.target.name === "help") {
      const val = helpOptions.filter(item => item.name === e.target.value)
      setbudgetDisable(val[0].budget)
    }
    setState({
      ...state,
      [e.target.name]: value,
    })
    setIsFailed(false)
  }

  async function handleSubmit(e) {
    e.preventDefault()
    if (state.name.length > 25) return
    setOpen(true)
    const form = document.forms["contact"]

    // fetch(
    //   "https://script.google.com/macros/s/AKfycbyVDyCG6u76JONl627-Z6vDPNXB8eFSLjlabDx4gUI4Qf_lSGZL40869BC2r50BiX3BVg/exec",
    //   { method: "POST", body: new FormData(form) }
    // )
    //   .then(() => {
    //     navigate("/thank-you")
    //   })
    //   .catch(error => console.error("Error!", error.message))
    const body = {
      // to: state.email, // Change to your recipient
      to: "sales@codefulcrum.com", // Change to your recipient
      subject: `Client Query: ${state.help}`,
      name: `Client Query: ${state.name}`,
      html: `
      <table style=" border: 1px solid; width: 100%; border-collapse: collapse; color:black; text-align:center">
      <thead>
        <tr>
        <th style=" border: 1px solid">Name</th>
        <th style=" border: 1px solid">Email</th>
        <th style=" border: 1px solid">Company</th>
        <th style=" border: 1px solid">Service</th>
        <th style=" border: 1px solid">Budget</th>
        <th style=" border: 1px solid">Message</th>
        </tr>
      </thead>
      <tbody>
        <tr>
        <th style=" border: 1px solid; font-weight:normal">${state.name}</th>
        <td style=" border: 1px solid">${state.email}</td>
        <td style=" border: 1px solid">${state.company}</td>
        <td style=" border: 1px solid">${state.help}</td>
        <td style=" border: 1px solid">${state.budget}</td>
        <td style=" border: 1px solid">${state.message}</td>
        </tr>
      </tbody>
      </table> 
      `,
    }

    sendMail(body)
      .then(res => {
        if (res.status == 200) {
          navigate("/thank-you")
        } else {
          setIsFailed(true)
        }
        setOpen(false)
      })
      .catch(err => {
        console.error({ err })
        setIsFailed(true)
      })
  }

  const errorText = <span style={{ color: "red" }}>Enter a valid name</span>

  const clearButton = () => {
    const form = document.forms["contact"]
    form.reset()
    setClearSelectFields(true)
  }

  return (
    <>
      {isFailed && (
        <Alert variant="outlined" severity="error" className="mui-alert">
          Message sending failed
        </Alert>
      )}
      <Loader open={open} />

      <form name="contact" onSubmit={handleSubmit}>
        <Grid container columnSpacing={5} rowSpacing={6}>
          <Grid item md={6} sm={12} xs={12}>
            <CssTextField
              onChange={onChangeHandler}
              id="standard-basic"
              label="Name"
              variant="standard"
              name="name"
              fullWidth
              required
              helperText={state.name.length > 25 && errorText}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <CssTextField
              onChange={onChangeHandler}
              id="standard-basic"
              label="Email"
              variant="standard"
              name="email"
              fullWidth
              required
              type="email"
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <CssTextField
              onChange={onChangeHandler}
              id="standard-basic"
              label="Company"
              variant="standard"
              name="company"
              fullWidth
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12} className="form-fields">
            <Select
              options={helpOptions}
              label="How can we help you?"
              name="help"
              value={state.help}
              onChange={onChangeHandler}
              clearSelectFields={clearSelectFields}
              resetSelectClearState={() => setClearSelectFields(false)}
              required
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} className="form-fields">
            <Select
              options={budgetOptions}
              label="Budget"
              name="budget"
              value={state.budget}
              disabled={budgetDisable}
              onChange={onChangeHandler}
              clearSelectFields={clearSelectFields}
              resetSelectClearState={() => setClearSelectFields(false)}
            />
          </Grid>
          <Grid item md={12} xs={12} className="form-fields">
            <textarea
              onChange={onChangeHandler}
              name="message"
              id=""
              cols="30"
              rows="4"
              placeholder="Message"
            ></textarea>
          </Grid>
        </Grid>
        <Grid className="text-right  pe-0 pt-5">
          <Button
            text="CANCEL"
            variant="fill"
            border="#2f2f2f"
            bgClr="#ffffff"
            clr="#2f2f2f"
            className="me-3"
            onClick={clearButton} //3
            type="button"
          />
          <Button
            text="SEND MESSAGE"
            variant="fill"
            bgClr="#f23b48"
            type="submit"
          />
        </Grid>
      </form>
    </>
  )
}
