import React from "react"
import { images } from "../../../static/index"
import Hero from "../shared/Hero"
// import ClientSection from "./ClientSection"

import ContactUs from "../shared/Footer/ContactUs"
import BlogSection from "./BlogSection"
import Clutch from "./Clutch"
const AboutUsSection = React.lazy(() => import("./AboutUsSection"))
const ServicesSection = React.lazy(() => import("./ServicesSection"))
const WorkSwiper = React.lazy(() => import("./WorkSwiper"))
const Achievments = React.lazy(() => import("./Achievments/Achievments"))
const ClientSection = React.lazy(() => import("./ClientSection"))

export default function Homepage() {
  const isSSR = typeof window === "undefined"

  return (
    <>
      <Hero
        id="top"
        social={true}
        btnText="BOOK A MEETING"
        text="Empowering businesses with cost-effective software solutions, efficient resource allocation, transparent communication, and streamlined work processes."
        scroll="#about_us_section"
      >
        <h1 style={{ fontDisplay: "swap" }}>
          Where Elite Coding Expertise Meets{" "}
          <span style={{ color: "#f23b48", fontDisplay: "swap" }}>
            Business Brilliance{" "}
          </span>
        </h1>
      </Hero>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ClientSection />
        </React.Suspense>
      )}

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <AboutUsSection />
        </React.Suspense>
      )}
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <ServicesSection />
        </React.Suspense>
      )}
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <WorkSwiper />
        </React.Suspense>
      )}
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Achievments />
        </React.Suspense>
      )}

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <BlogSection />
        </React.Suspense>
      )}

      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <Clutch />
        </React.Suspense>
      )}
      <React.Suspense fallback={<div />}>
        <ContactUs />
      </React.Suspense>
    </>
  )
}
