import React from "react"

function Scroll({ direction }) {
  return direction !== "up" ? (
    <div id="wrapper-down">
      <div id="wrapper-inner-down">
        <div id="scroll-down">
          <span className="arrow-down"></span>
        </div>
      </div>
    </div>
  ) : (
    <div id="wrapper-up">
      <div id="wrapper-inner-up">
        <span className="arrow-up"></span>

        <div id="scroll-up"></div>
      </div>
    </div>
  )
}

export default Scroll
