import React from "react"
import Layout from "../components/Layout"

import Homepage from "../components/Homepage"
import Head from "../components/utils/Head"
import { Helmet } from "react-helmet"

export default function Home() {
 




  return (
    <>
      <Layout>
        <Head
          metaTitle="Custom Software Development Company - CodeFulcrum"
          title="Custom Software Development Company - CodeFulcrum"
          desc="At our software development company, we specialize in developing custom software solutions that are tailored to your specific needs."
        />
       <Helmet>
          <link rel="canonical" href="https://codefulcrum.com/" />
        </Helmet>
        <Homepage />
     
      </Layout>
    </>
  )
}
