import { Grid } from "@mui/material"
import React, { useEffect, useState } from "react"
import Button from "../Button/Button"
import Heading from "../Heading"
import { StaticImage } from "gatsby-plugin-image"
import "./Hero.scss"
// import { StaticImage } from "gatsby-plugin-image"
// import Scroll from "../../utils/Scroll"

export default function Hero({
  btnText,
  img,
  children,
  text,
  social,
  scroll,
  id,
}) {
  useEffect(() => {
    if (typeof window !== "undefined") {
      import("@dotlottie/player-component")
    }
  }, [])
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    // Function to handle the page load event
    const handleLoad = () => {
      setIsLoaded(true)
    }

    // Add event listener for when the page finishes loading
    window.addEventListener("load", handleLoad)

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("load", handleLoad)
    }
  }, [])

  return (
    <div className="hero-bg" id={id}>
      <div className="container position-relative">
        <Grid container className="pb-4">
          <Grid sm={12} md={6} className="hero-text-column">
            <Heading variant="58" className="font-bold">
              {" "}
              {children}{" "}
            </Heading>
            {!!text ? <div className="hero-text">{text}</div> : null}
            <Button text={btnText} variant="fill" href="/contact" />
          </Grid>
          <Grid sm={12} md={6} className=" mt-sm-0 mt-5">
            <div className="hero-img-section">
              {/* {!isLoaded ? (
                <StaticImage
                  src={"../../../../static/Presenting analytics1.png"}
                  placeholder="none"
                  formats={["auto", "webp", "png"]}
                  alt="img"
                />
              ) : ( */}
              <dotlottie-player
                src="/homepage/presenting-analytics.lottie"
                autoplay
                loop
                style={{ width: "100%", margin: 0 }}
              />
              {/* )} */}
            </div>
          </Grid>
        </Grid>
        {!!social ? (
          <div
            className="social-icons-box"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
            data-aos-duration="1000"
          >
            <div className="social-heading">Follow Us</div>
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/codefulcrum/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
                // id="test"
              >
                {/* <span>Visit Profile</span> */}
                <img
                  loading="lazy"
                  src="/linkedin.svg"
                  alt="linkedin-icon"
                  className="img-fluid"
                />
              </a>
              <a
                href="https://instagram.com/codefulcrum_?igshid=YmMyMTA2M2Y="
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/instagram.svg"
                  alt="instagram-icon"
                  className="img-fluid"
                />
              </a>
              <a
                href="https://www.facebook.com/codeFulcrum/"
                target="_blank"
                className="social-icon"
                rel="noreferrer"
              >
                <img
                  loading="lazy"
                  src="/facebook.svg"
                  alt="facebook-icon"
                  className="img-fluid"
                />
              </a>
            </div>
          </div>
        ) : null}
        {/* <a href={scroll} className="scroll-down-global">
          <div className="icon ">
            <Scroll />
          </div>
          <div className="text">SCROLL DOWN</div>
        </a> */}
      </div>
    </div>
  )
}
